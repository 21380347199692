import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "d-flex flex-stack flex-grow-1" }
const _hoisted_2 = {
  key: 0,
  class: "text-gray-900 fw-bolder"
}
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = ["href", "data-bs-toggle", "data-bs-target"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_inline_svg = _resolveComponent("inline-svg")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(`notice
      d-flex
      bg-light-${_ctx.color}
      rounded
      border-${_ctx.color}
      border
      border-dashed
      ${_ctx.classes}
     ${_ctx.padding}`)
  }, [
    (_ctx.icon)
      ? (_openBlock(), _createElementBlock("span", {
          key: 0,
          class: _normalizeClass(`svg-icon svg-icon-2tx svg-icon-${_ctx.color} me-4 ms-15 ms-lg-15`)
        }, [
          _createVNode(_component_inline_svg, { src: _ctx.icon }, null, 8, ["src"])
        ], 2))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_1, [
      (_ctx.body || _ctx.title)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: _normalizeClass(`fw-bold ${_ctx.button}`)
          }, [
            (_ctx.title)
              ? (_openBlock(), _createElementBlock("h4", _hoisted_2, _toDisplayString(_ctx.title), 1))
              : _createCommentVNode("", true),
            (_ctx.body)
              ? (_openBlock(), _createElementBlock("div", {
                  key: 1,
                  innerHTML: _ctx.body,
                  class: _normalizeClass(`fs-6 text-gray-700 pe-7`)
                }, null, 8, _hoisted_3))
              : _createCommentVNode("", true)
          ], 2))
        : _createCommentVNode("", true),
      (_ctx.button)
        ? (_openBlock(), _createElementBlock("a", {
            key: 1,
            href: _ctx.buttonUrl,
            class: _normalizeClass(`btn btn-${_ctx.color} px-6 align-self-center text-nowrap`),
            "data-bs-toggle": _ctx.buttonModalId ? 'modal' : false,
            "data-bs-target": _ctx.buttonModalId ? _ctx.buttonModalId : false
          }, _toDisplayString(_ctx.buttonLabel), 11, _hoisted_4))
        : _createCommentVNode("", true)
    ])
  ], 2))
}